import { Navigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";

const PrivateRoutes = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkJwt = async () => {
            try {
                const response = await fetch("/verifyJWT", {
                    credentials: "include"
                });
                if (response.status === 401 || response.status === 404) {
                    const message = await response.text();
                    if (message === "no access") {
                        return <Navigate to="/noaccess" />;
                    } else {
                        setIsAuthenticated(false);
                    }
                } else {
                    setIsAuthenticated(true);
                }
            } catch (error) {
                console.error(error);
                setIsAuthenticated(false);
            } finally {
                setIsLoading(false);
            }
        };

        checkJwt();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;

import { useState, useEffect } from "react";
import { DefaultButton, Dialog, DialogFooter } from "@fluentui/react";

import styles from "./TermsModal.module.scss";

export const getTermsDocumentCookie = () => {
    return document.cookie;
};

const TermsModal = () => {
    const [hideDialog, setHideDialog] = useState(true);

    const showDialog = () => {
        setHideDialog(false);
    };

    const closeDialog = () => {
        setHideDialog(true);

        const hasTermsModalShown = document.cookie.includes("termsModalShown=true");
        if (!hasTermsModalShown) {
            // condition to prevent refreshing of cookie every time dialog is closed
            document.cookie = "termsModalShown=true; max-age=3600"; // Set the cookie to remember the dialog has been shown
        }
    };

    useEffect(() => {
        const hasTermsModalShown = document.cookie.includes("termsModalShown=true");
        if (!hasTermsModalShown) {
            // console.log("SHOWING DIALOG");
            showDialog(); // Show the dialog if the cookie has not been set
        }
    }, []);

    return (
        <div>
            <a className={styles.headerNavPageLinkActive} href="#" onClick={showDialog} data-testid="terms-header-button">
                Terms of Use
            </a>

            <Dialog
                hidden={hideDialog}
                onDismiss={closeDialog}
                dialogContentProps={{
                    showCloseButton: false,
                    title: <div className={styles.modalColor}>Terms of Use</div>
                }}
                modalProps={{
                    isBlocking: true,
                    styles: {
                        main: {
                            borderRadius: 8,
                            selectors: {
                                ["@media (min-width: 480px)"]: {
                                    width: "80vw",
                                    maxWidth: "900px"
                                }
                            }
                        }
                    }
                }}
            >
                <ul data-testid="terms-text">
                    <li>Users may only share information that is classified as 'Public' when using this model.</li>
                    <li>
                        This model may be used for internal use-cases only (i.e., not to be used for initiatives that are client-facing or facing any other
                        external party).
                    </li>
                    <li>All output from the model must always be reviewed by a human prior to the output being used (100% Human-In-The-Loop).</li>
                    <li>Please note that this model is based only on public data available up to September 2021.</li>
                </ul>
                <DialogFooter>
                    <DefaultButton onClick={closeDialog} text="Accept Terms of Use" data-testid="terms-close-button" />
                </DialogFooter>
            </Dialog>
        </div>
    );
};

export default TermsModal;

import { Outlet, NavLink, Link } from "react-router-dom";

import github from "../../assets/github.svg";
import scvLogo from "../../assets/scv-logo.svg";

import styles from "./Layout.module.scss";

import TermsModal from "../../components/TermsModal/TermsModal";

const Layout = () => {
    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <img src={scvLogo} alt="SCV logo" aria-label="SCV logo" />
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li>
                                <NavLink to="/" className={styles.headerNavPageLinkActive}>
                                    Chat
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink to="/codechat" className={styles.headerNavPageLinkActive}>
                                    Code Assistant
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <a
                                    className={styles.headerNavPageLinkActive}
                                    href="https://thebridge.zone1.scb.net/community/functions/operations/cdo/rai"
                                    target="_blank"
                                >
                                    Responsible AI
                                </a>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <a
                                    className={styles.headerNavPageLinkActive}
                                    href="https://thebridge.zone1.scb.net/community/functions/operations/cdo/rai/blog/2023/04/13/chatgptllms-doing-the-right-thing-for-our-clients-and-the-bank"
                                    target="_blank"
                                >
                                    ChatGPT/LLMs
                                </a>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <a className={styles.headerNavPageLinkActive} href="https://thebridge.zone1.scb.net/docs/DOC-824838" target="_blank">
                                    Usage Of AI
                                </a>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <a className={styles.headerNavPageLinkActive} href="#">
                                    <TermsModal />

                                    {/* Terms of Use */}
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <h4 className={styles.headerRightText}>OpenAI Prototype</h4>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;

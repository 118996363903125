import { Stack, IconButton } from "@fluentui/react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { useMemo } from "react";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
// import DOMPurify from "dompurify";

import styles from "./CodeAnswer.module.css";

import { AskResponse, getCitationFilePath } from "../../api";

interface Props {
    answer: AskResponse;
}

export const CodeAnswer = ({ answer }: Props) => {
    const responseArray = answer.answer.split(/(```[\s\S]*?```)/g).map((content, index) => {
        if (content.startsWith("```")) {
            var regex = /```(\w+)?/g;
            const regexMatches = content.match(regex);

            const firstLine = regexMatches ? (regexMatches[0].length > 3 ? regexMatches[0].slice(3) : "javascript") : "";

            return { type: "code", content: content.slice(firstLine.length + 3, -3), language: firstLine };
        } else {
            return { type: "message", content };
        }
    });

    const handleCopyClick = async (code: string) => {
        await navigator.clipboard.writeText(code);
    };

    return (
        <Stack className={`${styles.answerContainer} `} verticalAlign="space-between">
            <Stack.Item grow>
                {responseArray.map((item, index) => {
                    if (item.type === "code") {
                        return (
                            <div className={styles.codeDiv}>
                                <IconButton className={styles.copyIcon} iconProps={{ iconName: "Copy" }} onClick={() => handleCopyClick(item.content)} />
                                <SyntaxHighlighter key={index} language={item.language}>
                                    {item.content}
                                </SyntaxHighlighter>
                            </div>
                        );
                    } else {
                        return <p key={index}>{item.content}</p>;
                    }
                })}
            </Stack.Item>

            <Stack.Item data-testid="last-item">
                <div className={styles.answerNotice}>
                    All chat output must be reviewed by a human prior to use. This model is based on public data available up to Sep 2021.
                </div>
            </Stack.Item>
        </Stack>
    );
};

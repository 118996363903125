import { createContext, StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import CodeChat from "./pages/codechat/CodeChat";
import Login from "./pages/login/Login";
import IframePage from "./pages/iframe";
import NoAccess from "./pages/noaccess/NoAccess";
import PrivateRoutes from "./utils/PrivateRoutes";

initializeIcons();

export default function App() {
    return (
        <HashRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route element={<PrivateRoutes />}>
                        <Route path="qa" element={<OneShot />} />
                        <Route path="codechat" element={<CodeChat />} />
                        <Route path="/" element={<Chat />} />
                        <Route path="iframe" element={<IframePage />} />
                    </Route>
                </Route>

                <Route path="login" element={<Login />} />
                <Route path="*" element={<NoPage />} />
                <Route path="noaccess" element={<NoAccess />} />
            </Routes>
        </HashRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <StrictMode>
        <App />
    </StrictMode>
);

import React from "react";

function IframePage() {
    return (
        <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
            <iframe
                src="http://dev.llm.azcloud.dev.net"
                style={{ flex: 1, border: "none" }}
                allowFullScreen
            />
            <footer style={{ textAlign: "center", padding: "10px" }}>
                This website was crafted with care and precision by a talented
                programmer Howell Blake
            </footer>
        </div>
    );
}

export default IframePage;

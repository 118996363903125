import scvLogo from "../../assets/scv-logo.svg";
import scvLogoSso from "../../assets/scv-logo-sso.svg";

import styles from "./Login.module.scss";

const handleClick = () => {
    window.location.href = window.location.origin + "/samllogin";
    // const host = "https://auth2.scvfintechbridge.com";
    // const client_id = "hjf6672e4koqs05t1kp0nn5je";
    // const callbackUrl = window.location.origin + "/verifyToken";
    // const identityProvider =
    //     window.location.host === "localhost:5000"
    //         ? "Google"
    //         : "scvfintechbridge";

    // const scope = "openid+profile+email+aws.cognito.signin.user.admin";
    // const responseType = "code";

    // const redirectUrl = `${host}/oauth2/authorize?client_id=${client_id}&redirect_uri=${encodeURIComponent(
    //     callbackUrl
    // )}&identity_provider=${identityProvider}&response_type=${responseType}&scope=${scope}`;

    // // Redirect the user to the constructed URL
    // window.location.href = redirectUrl;
};

const Login = () => {
    return (
        <div className={`${styles.flexcenter} ${styles.loginSectionCon}`}>
            <div className={`${styles.width360} ${styles.loginSection}`}>
                <img
                    src={scvLogo}
                    alt="Standard Chartered Ventures"
                    className={styles.scvLogo}
                />
                <h2>Welcome Back</h2>
                <p>Login with your registered Standard Chartered Account</p>
                <p className={`${styles.textAlignCenter}`}>
                    Please note that the information or content on a website
                    being restricted to selected personnel implies that access
                    is limited to specific individuals.
                </p>
                <button className={styles.loginBtn} onClick={handleClick}>
                    <img
                        src={scvLogoSso}
                        alt="Standard Chartered Ventures"
                        className={styles.scvLogoSso}
                    />
                    <span>Login with SSO</span>
                </button>
            </div>
        </div>
    );
};

export default Login;

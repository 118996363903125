import scvLogo from "../../assets/scv-logo.svg";
import scvLogoSso from "../../assets/scv-logo-sso.svg";

import styles from "./NoAccess.module.scss";

const NoAccess = () => {
    return (
        <div className={`${styles.flexcenter} ${styles.loginSectionCon}`}>
            <div className={`${styles.width360} ${styles.loginSection}`}>
                <img src={scvLogo} alt="Standard Chartered Ventures" className={styles.scvLogo} />
                <h2>Access Denied</h2>
                <p className={`${styles.textAlignCenter}`}>
                    Please note that the information or content on a website being restricted to selected personnel implies that access is limited to specific
                    individuals.
                </p>
            </div>
        </div>
    );
};

export default NoAccess;
